$link-size: 1rem;

.lang-switch {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: .5rem;

    &__link {
        padding: .5rem;
        font-size: 0;
        display: block;
        width: $link-size;
        height: $link-size;

        transition: opacity .3s ease-in-out;
        opacity: .5;

        &:hover,
        &:focus,
        &.active {
            opacity: 1;
        }

        @each $flag in (cs, en, de) {
            &--#{$flag} {
                background: {
                    repeat: no-repeat;
                    size: 100%;
                    image: url('../img/lang/#{$flag}.png');
                }
            }
        }
    }
}
