$primary: #1694a4;
$secondary: #1cbdd1;
$ternary: #16a46d;

$text: #fff;

$heading: lighten($primary, 50%);
$heading-sub: lighten($secondary, 30%);

$link: lighten($secondary, 40%);
$link-active: lighten($primary, 30%);

$main-bg-1: rgba($primary, .8);
$main-bg-2: rgba(darken($secondary, 50%), .5);
$main-border: rgba(#fff, .3);

$btn-bg-1: $secondary;
$btn-bg-2: $primary;
$btn-color: $text;
$btn-shadow: rgba(#000, .2);

$table-border: rgba(#fff, .3);

$gallery-navigation: rgba($secondary, .5);
$gallery-bg--fullscreen: rgba(#000, .9);