@use "conf/colors.scss" as color;
@use "mixins/media.scss" as *;

@import "normalize";
@import "default";

html {
    background: url("../img/bg.webp") no-repeat 50% 0;
    background-attachment: fixed;
    background-size: cover;
    color: color.$text;
}

body {
    font-family: "Montserrat", sans-serif;
}

.main {
    max-width: 980px;
    margin: 0 auto;
    background-image: linear-gradient(color.$main-bg-1 3%, color.$main-bg-2);
    padding: 1.5em 2em;
    box-sizing: border-box;
    border: {
        width: 0 1px;
        style: solid;
        color: color.$main-border;
    }
}

h1 {
    color: color.$heading;
    margin: 0;
}

.gallery-header {
    display: flex;
    align-items: baseline;
    gap: .5em;
}

.subtitle {
    font-size: smaller;
    opacity: .8;
}

h2 {
    color: color.$heading-sub;
    font-weight: 500;
}

a {
    color: color.$link;

    &:focus,
    &:hover {
        color: color.$link-active;
    }
}

.w-table {
    max-width: 100%;
    overflow: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid color.$table-border;
    padding: 0.3em 0.4em;
}

tbody {
    th {
        text-align: left;
    }
}

.w-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    text-align: center;
    margin: 2em 0;
}

.btn {
    display: inline-block;
    padding: 0.7em 1.5em;
    text-transform: uppercase;
    font-weight: 500;
    color: color.$btn-color;
    background-image: linear-gradient(color.$btn-bg-1, color.$btn-bg-2);
    text-decoration: none;
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    border-radius: 0.7em;
    box-shadow: color.$btn-shadow 0 0.5em 0.5em;

    &:focus,
    &:hover {
        color: color.$btn-color;
        transform: scale(1.05);
    }
}

address {
    line-height: 1.5;
}

@import "widget/gallery.scss";
@import "widget/header.scss";
@import "widget/lang.scss";
