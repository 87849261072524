$breakpoint: 600px;

.header {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    row-gap: 1rem;

    @include mediaWidth($breakpoint) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    h1 {
        align-self: flex-start;
    }
}