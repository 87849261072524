@use '../conf/breakpoints.scss' as bp;

@mixin mediaPrint
{
    @media only print
    {
        @content;
    }
}

@mixin mediaScreen
{
    @media only screen
    {
        @content;
    }
}

@mixin mediaWidth($args...)
{
    @if length($args) > 0 and nth($args, 1) != false
    {
        @each $type in $args
        {
            @if $type == "tablet"
            {
                @media only screen and (min-width: bp.$tablet) and (max-width: (bp.$desktop - .1))
                {
                    @content;
                }
            }
            @else if ($type == "desktop")
            {
                @media only screen and (min-width: bp.$desktop)
                {
                    @content;
                }
            }
            @else if ($type == "mobile")
            {
                @media only screen and (max-width: (bp.$tablet - .1))
                {
                    @content;
                }
            }
            @else
            {
                @if length($type) == 1
                {
                    @media only screen and (min-width: $type)
                    {
                        @content;
                    }
                }
                @else if (length($type) == 2)
                {
                    $min: nth($type, 1);
                    $max: nth($type, 2);

                    @if $min != null and $max != null
                    {
                        @media only screen and (min-width: $min) and (max-width: ($max + .9))
                        {
                            @content;
                        }
                    }
                    @else if ($min != null)
                    {
                        @media only screen and (min-width: $min)
                        {
                            @content;
                        }
                    }
                    @else if ($max != null)
                    {
                        @media only screen and (max-width: ($max + .9))
                        {
                            @content;
                        }
                    }
                }
            }
        }
    }
    @else
    {
        @content;
    }
}

@mixin mediaTablet
{
    @include mediaWidth(bp.$tablet)
    {
        @content;
    }
}

@mixin mediaDesktop
{
    @include mediaWidth(bp.$desktop)
    {
        @content;
    }
}