$arrow-size: 26px;

.gallery {
    position: relative;
    display: flex;
    padding: 0 $arrow-size;

    & > .gallery-arrow {
        --swiper-navigation-size: #{$arrow-size};
        --swiper-navigation-sides-offset: 0;
    }
}

@import "swiper.scss";