@use "../conf/colors.scss" as color;
@use "../conf/breakpoints.scss" as bp;
@use "../mixins/media.scss" as *;

$slide-scale-init: .95;

.swiper-slide {
    max-height: 200px;
    box-sizing: border-box;

    &.swiper-slide {
        display: flex;
    }

    img {
        width: 100%;
        object-fit: contain;
        transition: transform 0.2s ease-in-out;
        transform: scale($slide-scale-init);
        cursor: pointer;

        &:hover,
        &:focus {
            transform: scale(1);
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    --swiper-navigation-color: #{color.$gallery-navigation};

    @include mediaWidth(null bp.$desktop - .02) {
        display: none !important;
    }
}

.swiper-button-close.swiper-button-close {
    display: none;
}

body.is-fullscreen {
    overflow: hidden;

    .swiper-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: color.$gallery-bg--fullscreen;
    }

    .swiper-slide {
        max-height: 95vh;

        img {
            cursor: default;

            &:hover,
            &:focus {
                transform: scale($slide-scale-init);
            }
        }
    }

    .swiper-button-close {
        display: inline-flex;
        position: absolute;
        top: 2em;
        right: 2em;
        left: auto;

        &::before {
            content: 'next';
            font-family: swiper-icons;
            text-transform: none !important;
            letter-spacing: 0;
            font-variant: initial;
            line-height: 1;
            transform: translateX(.1em);
        }

        &::after,
        &::before {
            font-size: calc(var(--swiper-navigation-size) * .7);
        }
    }
}